<template>
  <div class="supplierTop5 board_box">
    <div class="board_title dp_f">
      <div class="title">供应商排名 <span class="en">TOP5</span><span class="unit"></span></div>
      <i class="collection iconfont icon-a-ziyuan16 cur_p"  @click='like()' v-if='isLike'/>
      <i class="collection iconfont icon-a-ziyuan15 cur_p" @click='like(true)' v-else/>
    </div>
    <div class="content" v-if='tableData.length > 0'>
      <div class="item dp_f" v-for="(item,index) in tableData " :key="index">
        <div class="name">
           <el-tooltip :content="item.thirdName" placement="top" effect="light" >
            <span >{{item.thirdName}}</span>
        </el-tooltip>
        </div>
        <div class="proess">
          <el-progress class="proessLine" :stroke-width='8' :color="getPressColor(index)" :percentage="item.percentageNum" :format="format"/>
          <el-tooltip :content="$filters.toYuan(item.value,'')" placement="top" effect="light">
            <span class="cur_p proessSpan">{{$filters.toYuan(item.value,'')}}</span>
          </el-tooltip>
        </div>
      </div>
    </div>
    <div class="content empty" v-else>
      <div class="zh_empty"></div>
    </div>
  </div>
</template>
<script setup>
import {onMounted, ref, toRefs, watch, getCurrentInstance} from "vue";
import {supplierCensus} from "@/utils/api/procurement/census.js";
import moment from 'moment'
const props = defineProps({
 formData: {
    type: Object,
    default: () => {},
  },
  isLike: {
    type: Boolean,
    default: false,
  },
});
const { proxy } = getCurrentInstance();
const { formData, isLike } = toRefs(props);
const tableData = ref([])
const unitId = ref(2);
const emit = defineEmits(["like"])
watch(
   () => formData.value,
  (val) => {
    getData()
  },
  { deep: true }
);
  const format =()=>{return ''}
  const getPressColor = (index)=>{
    if(index%2 == 0){
      return '#57B0FF'
    }
    return '#3859FF'
  }
const getData = () => {
  supplierCensus({
    stockId:formData.value.stockId || 0,
    beginTime:formData.value.time && formData.value.time[0] ? moment(formData.value.time[0]).valueOf() : 0,
    endTime:formData.value.time && formData.value.time[1] ? moment(formData.value.time[1]).valueOf() : 0,
  }).then((res) => {
      if(res.code != 0){
        proxy.$message.error(res.msg)
        return
      }
      let maxNum = 0;
      if(res.data.list && res.data.list.length > 0){
        maxNum = res.data.list[0].value
        res.data.list.forEach(item=>{
          item.proess = item.value/maxNum * 100
          //percentageNum保留整数
          item.percentageNum = Math.round(item.proess)
        })
      }
      tableData.value = res.data.list || []
  })
};
onMounted(() => {
  getData()
})
const like = (type = false)=>{
  emit("like",{
    unitId:unitId.value,
    type
  } );
}
</script>

<script>
export default {
  name:'supplierTop5',
}
</script>

<style lang="scss" scoped>
@import './index.scss';
.supplierTop5{
   width: 49.5%;
   height: 296px;
   .content{
    width: 100%;
    margin-top: 30px;
    height: 184px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &.empty{
     display: block;
    }
    .item{
      margin-bottom: 23px;
      width: 100%;
      .name{
        @include textOverflow(14%)
      }
      .proess{
        width: 86%;
        display: flex;
        .proessLine{
          width: 85%;
          overflow: hidden;
        }
        .proessSpan{
          display: inline-block;
          @include textOverflow(15%);
          width: 15%;
        }
      }
    }
   }
}
</style>
