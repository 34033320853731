import request from '../../http'
import {managerUrl} from '../../api'

//采购订单统计接口
export function orderCensus(data){
  return request({
    url:managerUrl + "/carton/buy/census/orders",
    method:'post',
    data
  })
}

//采购分类统计接口
export function classifyCensus(data){
  return request({
    url:managerUrl + "/carton/buy/census/classify",
    method:'post',
    data
  })
}

//采购供应商统计接口
export function supplierCensus(data){
  return request({
    url:managerUrl + "/carton/buy/census/supplier",
    method:'post',
    data
  })
}


//采购产品统计接口
export function productCensus(data){
  return request({
    url:managerUrl + "/carton/buy/census/product",
    method:'post',
    data
  })
}

//采购订单top5
export function orderTop5(data){
  return request({
    url:managerUrl + "/carton/buy/order/top",
    method:'post',
    data
  })
}