<template>
  <div class="total board_box">
    <div class="board_title dp_f">
      <div class="title">采购合计</div>
    <i class="collection iconfont icon-a-ziyuan16 cur_p"  @click='like()' v-if='isLike'/>
     <i class="collection iconfont icon-a-ziyuan15 cur_p" @click='like(true)' v-else/>
    </div>
    <div class="content dp_f cur_p">
      <div class="item item1">
        <div class="top">
          <div class="num">{{orderInfo.totalOrders}}</div>
          <div class="text">采购订单</div>
        </div>
        <div class="bottom">
          <div class="num">{{$filters.toYuan(orderInfo.totalPrice,'')}}</div>
          <div class="text">采购金额</div>
        </div>
      </div>
      <div class="item item2">
        <div class="top">
          <div class="num">{{orderInfo.incompleteOrders}}</div>
          <div class="text">待入库订单</div>
        </div>
        <div class="bottom">
          <div class="num">{{$filters.toYuan(orderInfo.incompletePrice,'')}}</div>
          <div class="text">待入库金额</div>
        </div>
      </div>
      <div class="item item3">
        <div class="top">
          <div class="num">{{orderInfo.completeOrders}}</div>
          <div class="text">已入库订单</div>
        </div>
        <div class="bottom">
          <div class="num">{{$filters.toYuan(orderInfo.completePrice,'')}}</div>
          <div class="text">已入库金额</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import {getCurrentInstance, reactive, ref, toRefs, watch} from "vue";
import {orderCensus} from "@/utils/api/procurement/census.js";
import moment from 'moment'
import gsap from 'gsap'

const props = defineProps({
   formData: {
    type: Object,
    default: () => {},
  },
  isLike: {
    type: Boolean,
    default: false,
  },
});
const unitId = ref(1);
const emit = defineEmits(["like"])
const { proxy } = getCurrentInstance();
const { formData, isLike } = toRefs(props);
const orderInfo = reactive({
  totalOrders: 0, // 采购订单数
  totalPrice: 0, //采购总金额 单位厘
  incompleteOrders: 0, //待验收订单数
  incompletePrice: 0, //integer
  completeOrders: 0, //已验收订单数
  completePrice: 0, //已验收总金额 单位厘
})
watch(
 () => formData.value,
  (val) => {
    getData()
  },
  {
    deep: true,
  }
);
const getData = () => {
  orderCensus({
    stockId:formData.value.stockId || 0,
  beginTime:formData.value.time && formData.value.time[0] ? moment(formData.value.time[0]).valueOf() : 0,
    endTime:formData.value.time && formData.value.time[1] ? moment(formData.value.time[1]).valueOf() : 0,
  }).then((res) => {
      if(res.code != 0){
        proxy.$message.error(res.msg)
        return
      }
    gsap.to(orderInfo, {
      duration: 0.5,
      totalOrders: res.data.totalOrders,
      totalPrice: res.data.totalPrice,
      incompleteOrders: res.data.incompleteOrders,
      incompletePrice: res.data.incompletePrice,
      completeOrders: res.data.completeOrders,
      completePrice: res.data.completePrice,
    });
    // orderInfo.totalOrders = res.data.totalOrders;
    // orderInfo.totalPrice = res.data.totalPrice;
    // orderInfo.incompleteOrders = res.data.incompleteOrders;
    // orderInfo.incompletePrice = res.data.incompletePrice;
    // orderInfo.completeOrders = res.data.completeOrders;
    // orderInfo.completePrice = res.data.completePrice;
  }).catch((err) => {

  });
};
getData()
const like = (type = false)=>{
  emit("like",{
    unitId:unitId.value,
    type
  } );
}
</script>

<script>
export default {
  name: "total",
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
.total {
  width: 49.5%;
  height: 296px;
  .content {
    margin-top: 16px;
    .item {
      @include composing(3, 1%);
      width: 33%;
      height: 192px;
      padding: 22px;
      box-sizing: border-box;
      border-radius: 16px;
      &.item1 {
        background: url("../../../assets/img/board/board_total_1.png") center
          center no-repeat;
        background-size: cover;
      }
      &.item2 {
        background: url("../../../assets/img/board/board_total_2.png") center
          center no-repeat;
        background-size: cover;
      }
      &.item3 {
        background: url("../../../assets/img/board/board_total_3.png") center
          center no-repeat;
        background-size: cover;
      }
      .num {
        font-size: 24px;
        color: $fsColor;
        @include textOverflow(100%);
        font-weight: 600;
        .unit {
          font-size: 18px;
        }
      }
      .text {
        font-size: 14px;
        color: $fsColor2;
        margin-top: 10px;
        //font-weight: 600;
      }
      .bottom {
        margin-top: 40px;
      }
    }
  }
}
</style>
