<template>
  <div class="order board_box">
    <div class="board_title dp_f">
      <div class="title">采购订单</div>
       <i class="collection iconfont icon-a-ziyuan16 cur_p"  @click='like()' v-if='isLike'/>
     <i class="collection iconfont icon-a-ziyuan15 cur_p" @click='like(true)' v-else/>
    </div>
    <div class="content">
      <el-table :data="tableData"  height="100%" @row-click="checkGoods"   :row-class-name="tableRowClassName"
          :cell-style="{border:'none'}"
          :header-row-style="{border:'none'}"  class="zh-no-table-border"  style="width: 100%" v-if='tableData.length > 0'>
          <el-table-column width="100" type="index"  align="center" label="序号" />
          <el-table-column   align="center" label="采购订单" class-name="table_text" show-overflow-tooltip>
            <template #default="scope">{{scope.row.orderSn}}
            </template>
          </el-table-column>
        <el-table-column align="center" label="供应商" class-name="table_text3" show-overflow-tooltip>
            <template #default="scope">{{scope.row.thirdName}}
            </template>
          </el-table-column>
          <el-table-column  prop="unitName" align="center" label="采购数量" >
            <template #default="scope">
              <div class="table_text2" >{{scope.row.count}}</div>
            </template>
          </el-table-column>
          <el-table-column  prop="unitName" align="center" label="采购金额（元）" class-name="table_text" show-overflow-tooltip>
            <template #default="scope">{{scope.row.totalPrice}}
            </template>
          </el-table-column>
        <el-table-column prop="unitName" align="center" label="入库时间">
            <template #default="scope">
              <div class="table_text2" >{{$filters.timeFormat(scope.row.time,1)}}</div>
            </template>
          </el-table-column>
      </el-table>
     <div class="zh_empty" v-else></div>
    </div>
       <!-- 订单详情 -->
    <OrderInfo
        ref="orderShow"
        @close="closeOrderInfo"
    ></OrderInfo>
  </div>
</template>
<script setup>
import OrderInfo from "@/views/order/components/orderDetailHistory.vue";
import {tableRowClassName} from '@/utils/tool.js'
import {getCurrentInstance, onMounted, reactive, ref, toRefs, watch} from "vue";
import {orderTop5} from "@/utils/api/procurement/census.js";
import moment from 'moment'
import {useStore} from "vuex";

const props = defineProps({
   formData: {
    type: Object,
    default: () => {},
  },
  isLike: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["like"])
const { proxy } = getCurrentInstance();
const { formData, isLike } = toRefs(props);
const tableData = ref([])
const store = useStore();
const unitId = ref(5);
const orderShow = ref(null);
watch(
   () => formData.value,
  (val) => {
    getData()
  },
  { deep: true }
);
const getData = () => {
  orderTop5({
    stockId:formData.value.stockId || 0,
    beginTime:formData.value.time && formData.value.time[0] ? moment(formData.value.time[0]).valueOf() : 0,
    endTime:formData.value.time && formData.value.time[1] ? moment(formData.value.time[1]).valueOf() : 0,
  }).then((res) => {
    if(res.code != 0){
      proxy.$message.error(res.msg)
      return
    }
    if(res.data?.length){
      res.data.forEach((item,index)=>{
        item.price = proxy.$filters.toYuan(item.price,'元')
        item.totalPrice = proxy.$filters.toYuan(item.totalPrice,'元')
      })
    }
    tableData.value = res.data || []
  })
};
onMounted(()=>{
  getData()
})
//点击采购订单详情
const checkGoods = (e) => {
  let obj = Object.assign({
    orderType: 1
  }, e)
  store.commit("PURCHASE_ORDER_INFO", obj);
  orderShow.value.openDetails();
};
const closeOrderInfo = () => {
};
const like = (type = false)=>{
  emit("like",{
    unitId:unitId.value,
    type
  } );
}
</script>

<script>
import { reactive,watch,ref,toRefs } from "vue";
export default {
  name:'order',

}
</script>

<style lang="scss" scoped>
@import './index.scss';
  .order{
    width: 100%;
    height: 440px;
    .content{
      margin-top: 16px;
      height: calc(100% - 34px);
    }
  }
</style>
